import { createTrackerForCategory } from '@gonfalon/analytics';
import { isAccountLapsed, isTrialAccount, planInfo, subscriptionState } from '@gonfalon/constants';
import { experimentsTrialBanner, selfServeCheckoutRecordedTermsVersion } from '@gonfalon/dogfood-flags';
import { trackError } from '@gonfalon/telemetry';

import type { BillingEventInfo, CancelFormFields } from './types';

export const trackBillingEvent = (name: string, eventData?: BillingEventInfo) => {
  const customerInfoWithEventData = {
    isAccountLapsed: isAccountLapsed(),
    isTrialAccount: isTrialAccount(),
    planInfo: planInfo(),
    subscriptionState: subscriptionState(),
    ...eventData,
  };

  return createTrackerForCategory('Billing')(name, customerInfoWithEventData);
};

export const trackTrialBannerCTAClicked = (daysLeftInTrial: number) => {
  const trialBannerVariation = experimentsTrialBanner();
  trackBillingEvent('Trial Banner CTA Clicked', { trialBannerVariation, daysLeftInTrial });
};

export const trackPlanPickerLoaded = (daysLeftInTrial: number) => {
  const trialBannerVariation = experimentsTrialBanner();
  trackBillingEvent('Billing Plan Picker Page Loaded', {
    trialBannerVariation,
    daysLeftInTrial,
  });
};

export const trackProceedToCheckoutButtonClicked = (daysLeftInTrial: number) => {
  const trialBannerVariation = experimentsTrialBanner();
  trackBillingEvent('Proceed To Checkout Button Clicked', {
    trialBannerVariation,
    daysLeftInTrial,
  });
};

export const trackTrialStatusPurchasePlanClicked = (daysLeftInTrial: number) =>
  trackBillingEvent('Trial Status Purchase Plan Clicked', { daysLeftInTrial });

export const trackFoundationAnnualExceedMonthlyEntitlementsClicked = () =>
  trackBillingEvent('Foundation Plan Annual Exceed Monthly Entitlements Link Clicked');

export const trackFoundationPlanUsageCalculatorClicked = () =>
  trackBillingEvent('Foundation Plan Usage Calculator Clicked');

export const trackEditPlanDetailsClicked = () =>
  trackBillingEvent('Plan Confirmation Edit Plan Details Button Clicked');

export const trackExtendMyEnterpriseTrialClicked = () => trackBillingEvent('Extend My Enterprise Trial Clicked');

export const trackExtendMyGuardianTrialClicked = () => trackBillingEvent('Extend My Guardian Trial Clicked');

export const trackSubscriptionCanceled = (reasons: CancelFormFields) =>
  trackBillingEvent('Subscription canceled', reasons);

export const trackSubscriptionCancelError = (error: Error, reasons: CancelFormFields) => {
  const e = new Error(error.message);
  e.name = 'SubscriptionCancelError';
  e.cause = error;

  // this goes to datadog
  trackError(e);

  // this goes to segment then snowflake, etc.
  trackBillingEvent('Subscription cancel error', { error: error.message, ...reasons });
};

export const trackSelfServeCheckoutTermsAccepted = () =>
  trackBillingEvent('Self Serve Checkout Terms Accepted', { termsVersion: selfServeCheckoutRecordedTermsVersion() });
